<script setup lang="ts">
const styles = useCssModule()
export interface Props {
  show: boolean
}
const props = withDefaults(defineProps<Props>(), {
  show: false,
})
const innerRef = ref<HTMLDivElement>()
const contentHeight = ref('')
const calcHeight = () => {
  if (!innerRef.value) return
  contentHeight.value = innerRef.value.getBoundingClientRect().height + 'px'
}
watch(
  () => props.show,
  () => nextTick(calcHeight),
)
onMounted(calcHeight)
</script>

<template>
  <Transition
    :enter-active-class="styles['fade-enter-active']"
    :leave-active-class="styles['fade-leave-active']"
    :enter-from-class="styles['fade-enter-from']"
    :leave-from-class="styles['fade-leave-from']"
    :enter-to-class="styles['fade-enter-to']"
    :leave-to-class="styles['fade-leave-to']"
  >
    <div v-show="show" :class="styles.container">
      <div ref="innerRef" :class="styles.content">
        <slot />
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" module>
.fade-enter-active,
.fade-leave-active {
  transition: height 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  height: 0;
}
.fade-enter-to,
.fade-leave-from {
  height: v-bind(contentHeight);
}
.container {
  overflow: clip;
}
</style>
